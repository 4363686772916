import SanityBlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

import {
  ContactForm,
  CopyWithFullHeightImage,
  CopyWithImages,
  Layout,
  SEO,
} from '../components';
import { CallToAction } from '../components/call-to-action';

function TreeFarmProjectPage({ data }) {
  return (
    <Layout
      heroImage={data.sanityTreeFarmProjectPage.heroImage.asset.fluid}
      heroAltText={data.sanityTreeFarmProjectPage.heroImage.altText}
    >
      <SEO title="Tree Farm Project" />
      <FoodForFurryFellas
        data={data.sanityTreeFarmProjectPage.foodForFurryFellas}
      />
      <ProjectRequirements
        data={data.sanityTreeFarmProjectPage.projectRequirements}
      />
      <Banner />
      <ContactForm />
    </Layout>
  );
}

TreeFarmProjectPage.propTypes = {
  data: PropTypes.object.isRequired,
};

function FoodForFurryFellas({ data }) {
  return (
    <CopyWithImages images={data.images}>
      <SanityBlockContent blocks={data._rawText} className="prose" />
      {data.callToAction ? (
        <CallToAction callToAction={data.callToAction} />
      ) : null}
    </CopyWithImages>
  );
}

FoodForFurryFellas.propTypes = {
  data: PropTypes.object.isRequired,
};

function ProjectRequirements({ data }) {
  return (
    <CopyWithFullHeightImage image={data.images[0]}>
      <SanityBlockContent blocks={data._rawText} className="prose" />
      {data.callToAction ? (
        <CallToAction callToAction={data.callToAction} />
      ) : null}
    </CopyWithFullHeightImage>
  );
}

ProjectRequirements.propTypes = {
  data: PropTypes.object.isRequired,
};

function Banner() {
  return (
    <article className="w-full px-4 py-16 mx-auto bg-white max-w-7xl sm:px-6 lg:px-8">
      <h2 className="text-2xl font-bold text-center uppercase text-green">
        If you have land available and would like to become{' '}
        <br className="hidden lg:inline" />
        involved with our project please contact us for more information
      </h2>
    </article>
  );
}

export const query = graphql`
  {
    sanityTreeFarmProjectPage {
      heroImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
        altText
      }
      foodForFurryFellas {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
      projectRequirements {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
    }
  }
`;

export default TreeFarmProjectPage;
